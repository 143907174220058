import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { Builder, parseString } from "xml2js";

import { assertUnreachable } from "./utils/assertUnreachable";
import { PageTypes } from "./utils/types/PageTypes";
import { SANITY_GPX_FILE } from "./utils/types/SanityGpxFile";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export type Parent = {
  parent: {
    slug: string | null;
  };
};

export interface SlugData {
  language?: string | null;
  slug: string;
  parentSlug?: string | null;
  grandparentSlug?: string | null;
}

export function composeURL(t: SlugData) {
  const { language, slug, parentSlug, grandparentSlug } = t;
  return [grandparentSlug, parentSlug, slug].filter(Boolean).join("/");
}

export const generateInternalLinkHref = (
  pageType: PageTypes,
  slug?: string | null,
  parentSlug?: null | string,
  grandparentSlug?: null | string,
  language?: null | string
) => {
  function getSlug() {
    switch (pageType) {
      case "webHome":
        return "";
      case "path":
        if (!slug) throw Error("Slug not defined");
        return `etaper/${slug}`;
      case "routesOverview":
        return `etaper`;
      case "webMap":
        return "kort";
      case "facility":
        if (!slug) throw Error("Slug not defined");
        return `kort/faciliteter/${slug}`;
      case "contentPage":
        if (!slug) throw Error("Slug not defined");
        return composeURL({
          language,
          slug,
          parentSlug,
          grandparentSlug,
        });
      case "contentOverviewPage":
        if (!slug) throw Error("Slug not defined");
        return `${slug}`;
      default:
        assertUnreachable(pageType);
    }
  }
  return language ? `/${language}/${getSlug()}` : `/${getSlug()}`;
};

export function getNumberIdentifier(str: string) {
  return str.replace(/[^0-9]/g, "");
}

export function formatDistance(
  distance: number,
  language: string,
  fractionDigits = 1
) {
  return distance.toLocaleString(language, {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });
}
